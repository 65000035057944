<template>
  <a-modal
    :width="420"
    title="个人资料"
    :visible="visible"
    :confirm-loading="loading"
    :body-style="{ paddingBottom: '16px' }"
    @update:visible="onUpdateVisible"
    @cancel="onCancel"
    @ok="onOk"
  >
    <a-form
      ref="form"
      :model="pageData"
      :rules="rules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <div class="user-info-avatar-group" @click="showCropper = true">
          <a-avatar :size="110" :src="pageData.avatar" />
          <upload-outlined class="user-info-avatar-icon" />
        </div>
      </a-form-item>
      <a-form-item label="呢称:" name="nickname">
        <a-input
          v-model:value="pageData.nickname"
          placeholder="请输入姓名"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="姓名:" name="realName">
        <a-input
          v-model:value="pageData.realName"
          placeholder="请输入姓名"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="手机号:" name="phone">
        <a-input
          readonly
          v-model:value="pageData.phone"
          placeholder="请输入手机号"
          allow-clear
        />
      </a-form-item>
      <a-form-item label="性别:" name="sex">
        <a-radio-group v-model:value="pageData.sex">
          <a-radio value="男">男</a-radio>
          <a-radio value="女">女</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="生日:" name="birthday">
        <a-date-picker
          valueFormat="YYYY-MM-DD"
          format="YYYY-MM-DD"
          style="width: 100%"
          v-model:value="pageData.birthday"
          placeholder="请选择生日"
        />
      </a-form-item>
      <!-- <a-form-item :wrapper-col="{ md: { offset: 6 } }">
        <a-button type="primary" @click="save" :loading="loading">
          {{ loading ? '保存中..' : '保存更改' }}
        </a-button>
      </a-form-item> -->
    </a-form>
    <!-- 头像裁剪弹窗 -->
    <ud-cropper-modal
      v-model:visible="showCropper"
      :src="pageData.avatar"
      @done="onCrop"
    />
  </a-modal>
</template>

<script>
import {
  UploadOutlined,
  PhoneOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons-vue'
import UdCropperModal from 'ud-admin-vue/packages/ud-cropper-modal'
import regions from '@/utils/regions'
import setting from '@/config/setting'
import { base64toFile } from '@/utils/util.js'
export default {
  name: 'UserInfo',
  components: {
    UploadOutlined,
    // PhoneOutlined,
    // MailOutlined,
    // UserOutlined,
    UdCropperModal
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      // tab页选中
      active: 'info',
      // 表单数据
      pageData: {},
      // 省市区数据
      cityData: regions,
      // 表单验证规则
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入昵称',
            type: 'string',
            trigger: 'blur'
          }
        ],
        sex: [
          {
            required: true,
            message: '请选择性别',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      uploadUrl: setting.uploadUrl,
      // 保存按钮loading
      loading: false,
      // 是否显示裁剪弹窗
      showCropper: false
    }
  },
  computed:{
    loginUser(){
      return this.$store.state.user.user
    }
  },
  watch: {
    loginUser:{
      handler(){
        this.initData()
      },
      deep:true
    }
  },
  methods: {
    /* 保存更改 */
    onOk() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          if (this.pageData.fullCity && this.pageData.fullCity.length === 3) {
            this.pageData.areaCode = this.pageData.fullCity[2]
          } else {
            this.pageData.areaCode = ''
          }
          this.$http
            .post('/app/user', this.pageData)
            .then((res) => {
              if (res.data.code === 0) {
                this.$message.success(res.data.msg)
                this.onUpdateVisible(false)
                this.$store.dispatch(
                  'user/setUser',
                  Object.assign({}, this.$store.state.user.user, this.pageData)
                )
              } else {
                this.$message.error(res.data.msg)
              }
              setTimeout(() => {
                this.loading = false
              }, 1000)
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        // const hide = this.$message.loading('上传中..', 0)
        console.log(formData, file)
        this.$http
          .post(this.uploadUrl, formData)
          .then((res) => {
            // hide()
            if (res.data.code === 0) {
              resolve(res.data)
            } else {
              reject(res.msg)
              this.$message.error(res.data.msg)
            }
          })
          .catch((e) => {
            reject(e)
            this.$message.error(e.message)
          })
      })
    },
    /* 头像裁剪完成回调 */
    async onCrop(res) {
      // const result = await this.uploadFile(res)
      // console.log('result', result)
      const file = base64toFile(res)
      const result = await this.uploadFile(file)
      // console.log('result', result)
      this.pageData.avatar = result.location
      // console.log('s', this.pageData.avatar)
      this.showCropper = false
      this.$store.dispatch(
        'user/setUser',
        Object.assign({}, this.$store.state.user.user, {
          avatar: result.location
        })
      )
    },
    initData() {
      const loginUser = this.loginUser
      this.pageData.userId = loginUser.userId
      this.pageData.usercode = loginUser.usercode
      this.pageData.username = loginUser.username
      this.pageData.realName = loginUser.realName
      this.pageData.nickname = loginUser.nickname
      this.pageData.sex = loginUser.sex + ''
      this.pageData.email = loginUser.email
      this.pageData.introduction = loginUser.introduction
      this.pageData.address = loginUser.address
      this.pageData.tell = loginUser.tell
      this.pageData.avatar = loginUser.avatar
      this.pageData.birthday = loginUser.birthday
      this.pageData.phone = loginUser.phone
      this.pageData.areaCode = loginUser.areaCode
      if (this.pageData.areaCode && this.pageData.areaCode.length === 6) {
        const fullCity = [
          this.pageData.areaCode.substring(0, 2).padEnd(6, '0'),
          this.pageData.areaCode.string(0, 4).padEnd(6, '0'),
          this.pageData.areaCode.substring(0, 6).padEnd(6, '0')
        ]
        this.pageData.fullCity = fullCity
      }
    },
    /* 关闭回调 */
    onCancel() {
      this.form = {}
      this.loading = false
      this.$refs.form.resetFields()
    },
    /* 修改visible */
    onUpdateVisible(value) {
      this.$emit('update:visible', value)
    }
  },
  created() {
    this.initData()
  }
}
</script>

<style scoped>
/* 用户资料卡片 */
.user-info-avatar-group {
  margin: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.user-info-avatar-group .user-info-avatar-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 30px;
  display: none;
  z-index: 2;
}

.user-info-avatar-group:hover .user-info-avatar-icon {
  display: block;
}

.user-info-avatar-group:hover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.user-info-avatar-group + h1 {
  margin-bottom: 8px;
}

/* 用户信息列表 */
.user-info-list {
  margin: 52px 0 32px 0;
}

.user-info-list .ud-cell + .ud-cell {
  margin-top: 16px;
}

.user-info-list + .ant-divider {
  margin-bottom: 16px;
}

/* 用户标签 */
.user-info-tags {
  margin: 16px 0 4px 0;
}

.user-info-tags .ant-tag {
  margin: 0 12px 8px 0;
}

/* 右侧卡片 */
.user-info-tabs :deep(.ant-card-body) {
  padding: 0;
}

.user-info-tabs :deep(.ant-tabs-tab) {
  padding-left: 4px;
  padding-right: 4px;
  margin: 0 12px 0 28px !important;
}

.user-info-tabs .ant-form {
  max-width: 580px;
  margin-top: 20px;
  padding: 0 24px;
}

/* 用户账号绑定列表 */
.user-account-list {
  margin-bottom: 27px;
}

.user-account-list > .ud-cell {
  padding: 18px 34px;
}

.user-account-list .user-account-icon {
  color: #fff;
  padding: 8px;
  font-size: 26px;
  border-radius: 50%;
}

.user-account-list .user-account-icon.anticon-qq {
  background: #3492ed;
}

.user-account-list .user-account-icon.anticon-wechat {
  background: #4daf29;
}

.user-account-list .user-account-icon.anticon-alipay {
  background: #1476fe;
}
</style>
